import { ChevronRightRounded } from "@mui/icons-material";

const Work = (props) => {
    const ChevronRightRoundedStyle = { fontSize: '26px', color: 'rgb(167, 163, 167)', verticalAlign: 'middle' };

    return (
        <div className='workItemContainer'>
            <div className='workItem'>
                <a href='https://www.podeux.com/' target='_blank'>

                    <img className='workImage' src='/workImage/podeux.png' />

                    <div className='workInfo'>
                        <div>
                            <span className='workName'>
                                Podeux
                            </span>
                            <ChevronRightRounded style={ChevronRightRoundedStyle} />
                        </div>

                        <div className='workDescription'>
                            Podcast Discovery
                        </div>
                        <div className='workDates'>
                            2021 - present
                        </div>
                    </div>

                </a>
            </div>
            <div className='workItem'>

                <a href='https://www.waymo.com' target='_blank'>
                    <img className='workImage' src='/workImage/waymo.png' />

                    <div className='workInfo'>
                        <div>
                            <span className='workName'>
                                Waymo
                            </span>
                            <ChevronRightRounded style={ChevronRightRoundedStyle} />
                        </div>

                        <div className='workDescription'>
                            Self-Driving Cars
                        </div>
                        <div className='workDates'>
                            2017 - 2021
                        </div>
                    </div>
                </a>
            </div>
            <div className='workItem'>
                <a href='https://area120.google.com/' target='_blank'>
                    <img className='workImage' src='/workImage/area120.png' />

                    <div className='workInfo'>
                        <div>
                            <span className='workName'>
                                Area 120
                            </span>
                            <ChevronRightRounded style={ChevronRightRoundedStyle} />
                        </div>
                        <div className='workDescription'>
                            Startup Incubator
                        </div>
                        <div className='workDates'>
                            2016 - 2017
                        </div>
                    </div>
                </a>
            </div>
            <div className='workItem' style={{ paddingBottom: '0px' }}>
                <a href='https://www.google.com/' target='_blank'>
                    <img className='workImage' src='/workImage/google.png' />
                    <div className='workInfo'>
                        <div>
                            <span className='workName'>
                                Google
                            </span>
                            <ChevronRightRounded style={ChevronRightRoundedStyle} />
                        </div>
                        <div className='workDescription'>
                            Search Infrastructure
                        </div>
                        <div className='workDates'>
                            2009 - 2016
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default Work;