

// Components.
import Music from "../components/Music";
import Name from "../components/Name";
import Photos from "../components/Photos";
import Work from "../components/Work";

const Home = (props) => {


  return (
    <div className='mainContainer'>
      <div className='main'>
        <div className='nameContainer'>
          <Name />
        </div>
        <div className='page-content'>
          <Photos />
        </div>
        <div className='page-content' style={{ padding: '10px 0px', margin: '10px 0px', borderBottom: '0.5px solid rgb(81, 81, 81)', borderTop: '0.5px solid rgb(81, 81, 81)' }}>
          <Work />
        </div>
        <div className='page-content'>
          <Music />
        </div>
      </div>
    </div >
  );
};

export default Home;