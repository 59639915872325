const Name = (props) => {
    return (
        <div>
            <div className='name'>
                John Hwang
            </div>
            <div>
                <a href='mailto:john@twinklepoo.com' className='contact'>
                    john@twinklepoo.com
                </a>
            </div>
        </div>
    );
};

export default Name;