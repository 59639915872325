import useWindowDimensions from "./windowHelper";

const Photos = (props) => {
    const { width } = useWindowDimensions();

    return (
        <div>
            <img src='/2023-venice.png' className='photo' style={{ width: `${width - 40}px` }} />
            <img src='/2018-uganda.png' className='photo' style={{ width: `${width - 40}px` }} />
            {/* <img src='/2016-greatwhite.png' className='photo' style={{ width: `${width - 40}px` }} /> */}
            <img src='/2023-dolomites.png' className='photo' style={{ width: `${width - 40}px`, marginBottom: '0px' }} />
        </div>
    );
};

export default Photos;